exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-all-blogs-index-jsx": () => import("./../../../src/pages/all-blogs/index.jsx" /* webpackChunkName: "component---src-pages-all-blogs-index-jsx" */),
  "component---src-pages-best-mobile-app-developer-by-clutch-index-jsx": () => import("./../../../src/pages/best-mobile-app-developer-by-clutch/index.jsx" /* webpackChunkName: "component---src-pages-best-mobile-app-developer-by-clutch-index-jsx" */),
  "component---src-pages-best-mobile-app-developer-index-jsx": () => import("./../../../src/pages/best-mobile-app-developer/index.jsx" /* webpackChunkName: "component---src-pages-best-mobile-app-developer-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-current-opening-index-jsx": () => import("./../../../src/pages/current-opening/index.jsx" /* webpackChunkName: "component---src-pages-current-opening-index-jsx" */),
  "component---src-pages-custom-software-development-services-index-jsx": () => import("./../../../src/pages/custom-software-development-services/index.jsx" /* webpackChunkName: "component---src-pages-custom-software-development-services-index-jsx" */),
  "component---src-pages-custom-software-index-jsx": () => import("./../../../src/pages/custom-software/index.jsx" /* webpackChunkName: "component---src-pages-custom-software-index-jsx" */),
  "component---src-pages-custom-software-service-index-jsx": () => import("./../../../src/pages/custom-software-service/index.jsx" /* webpackChunkName: "component---src-pages-custom-software-service-index-jsx" */),
  "component---src-pages-dedicated-force-index-jsx": () => import("./../../../src/pages/dedicated-force/index.jsx" /* webpackChunkName: "component---src-pages-dedicated-force-index-jsx" */),
  "component---src-pages-hire-dedicated-force-index-jsx": () => import("./../../../src/pages/hire-dedicated-force/index.jsx" /* webpackChunkName: "component---src-pages-hire-dedicated-force-index-jsx" */),
  "component---src-pages-hiring-index-jsx": () => import("./../../../src/pages/hiring/index.jsx" /* webpackChunkName: "component---src-pages-hiring-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mobile-application-development-services-index-jsx": () => import("./../../../src/pages/mobile-application-development-services/index.jsx" /* webpackChunkName: "component---src-pages-mobile-application-development-services-index-jsx" */),
  "component---src-pages-mobile-development-index-jsx": () => import("./../../../src/pages/mobile-development/index.jsx" /* webpackChunkName: "component---src-pages-mobile-development-index-jsx" */),
  "component---src-pages-mobile-development-service-index-jsx": () => import("./../../../src/pages/mobile-development-service/index.jsx" /* webpackChunkName: "component---src-pages-mobile-development-service-index-jsx" */),
  "component---src-pages-on-demands-solutions-index-jsx": () => import("./../../../src/pages/on-demands-solutions/index.jsx" /* webpackChunkName: "component---src-pages-on-demands-solutions-index-jsx" */),
  "component---src-pages-our-blogs-index-jsx": () => import("./../../../src/pages/our-blogs/index.jsx" /* webpackChunkName: "component---src-pages-our-blogs-index-jsx" */),
  "component---src-pages-portfolio-index-jsx": () => import("./../../../src/pages/portfolio/index.jsx" /* webpackChunkName: "component---src-pages-portfolio-index-jsx" */),
  "component---src-pages-privacy-policies-index-jsx": () => import("./../../../src/pages/privacy-policies/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policies-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-quality-assurance-service-index-jsx": () => import("./../../../src/pages/quality-assurance-service/index.jsx" /* webpackChunkName: "component---src-pages-quality-assurance-service-index-jsx" */),
  "component---src-pages-services-01-copy-index-jsx": () => import("./../../../src/pages/services-01 copy/index.jsx" /* webpackChunkName: "component---src-pages-services-01-copy-index-jsx" */),
  "component---src-pages-services-01-index-jsx": () => import("./../../../src/pages/services-01/index.jsx" /* webpackChunkName: "component---src-pages-services-01-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-services-section-index-jsx": () => import("./../../../src/pages/services-section/index.jsx" /* webpackChunkName: "component---src-pages-services-section-index-jsx" */),
  "component---src-pages-ui-design-service-index-jsx": () => import("./../../../src/pages/ui-design-service/index.jsx" /* webpackChunkName: "component---src-pages-ui-design-service-index-jsx" */),
  "component---src-pages-web-design-development-services-index-jsx": () => import("./../../../src/pages/web-design-development-services/index.jsx" /* webpackChunkName: "component---src-pages-web-design-development-services-index-jsx" */),
  "component---src-pages-web-development-index-jsx": () => import("./../../../src/pages/web-development/index.jsx" /* webpackChunkName: "component---src-pages-web-development-index-jsx" */),
  "component---src-pages-web-development-service-index-jsx": () => import("./../../../src/pages/web-development-service/index.jsx" /* webpackChunkName: "component---src-pages-web-development-service-index-jsx" */)
}

